<template>
  <div>
    <div class="main-title">弹窗广告</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">
              课程路径为：packageProduct/course/detail?id=
            </li>
            <li data-v-69874ad0="">
              组合购路径为：packageProduct/activity/promotion/detail?id=
            </li>
            <li data-v-69874ad0="">
              直播试听路径为：pages/promotionPage/trialCourse?id=
            </li>
          </ul>
        </div>
      </div>
      <div class="list-head-box"></div>

      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.type"
            clearable
            placeholder="请选择类型"
            size="mini"
          >
            <el-option :value="1" label="仅一次"> </el-option>
            <el-option :value="2" label="时间段"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="请选择状态"
            size="mini"
          >
            <el-option :value="0" label="下架"> </el-option>
            <el-option :value="1" label="上架"> </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l margin-t-sm">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <el-button
            size="small"
            type="primary"
            @click="$router.push('advertising/add')"
            >新增</el-button
          >
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>

          <el-table-column label="图片" property="address">
            <template slot-scope="scope">
              <el-image :src="imageUrl + scope.row.pic" />
            </template>
          </el-table-column>
          <el-table-column label="小程序链接" property="page" />

          <el-table-column label="类型" property="address">
            <template slot-scope="scope">
              <span>{{ scope.row.type === 1 ? "仅一次" : "时间段" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="状态" property="address">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="changeStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column label="开始时间" property="address">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 2">{{ scope.row.start_at }}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>

          <el-table-column label="结束时间" property="address">
            <template slot-scope="scope">
              <span v-if="scope.row.type === 2">{{ scope.row.end_at }}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" property="created_at" />
          <el-table-column label="更新时间" property="updated_at" />

          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <el-link
                type="primary"
                @click="$router.push('/advertising/edit/' + scope.row.id)"
                >编辑
              </el-link>
              -

              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="advertisingDel(scope.row.id)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  name: "List",
  data() {
    return {
      searchData: {},
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
      imageUrl: config.imageUrl,
    };
  },
  methods: {
    ...mapActions("advertising", [
      "toastAdList",
      "toastAdDel",
      "toastAdSetStatus",
    ]),
    async changeStatus(row) {
      try {
        let form = {
          id: row.id,
          status: row.status,
        };
        const { res_info } = await this.toastAdSetStatus(form);
        if (res_info !== "ok") return;
        this.$message.success("设置成功");
      } catch (e) {
        console.log(e);
      }
    },
    search() {
      this.page = 1;
      this.getList();
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
    },
    async advertisingDel(id) {
      try {
        const { res_info } = await this.toastAdDel({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
        await this.getList();
      } catch (e) {
        console.log(e);
      }
    },
    setPage(page) {
      this.page = page;
    },
    async getList() {
      try {
        this.loading = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.toastAdList(form);
        this.tableData = data.list;
        this.total = data.total;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>